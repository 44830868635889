import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const DropdownList = ({ options, placeholder, onChange, value }) => (
	<Dropdown
		placeholder={placeholder}
		fluid
		selection
		options={options}
		onChange={onChange}
		value={value}		
	/>
);

export default DropdownList;
