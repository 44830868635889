/* eslint-disable no-shadow */
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { withAuthenticator, Connect } from "aws-amplify-react";
import { graphqlOperation } from "aws-amplify";
import {
  Button,
  Header,
  Icon,
  Table,
  Grid,
  Message,
  Rating,
  Modal,
  ModalHeader,
  ModalContent,
  Form,
  GridRow,
  GridColumn,
  TextArea,
  ModalActions,
} from "semantic-ui-react";
import BaseLayout from "../../components/templates/BaseLayout";
import { getAllFeedbacks } from "../../graphql/queries";
import "./styles.css";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { deleteReview, updateReview } from "../../graphql/mutations";

const moment = require("moment");

const Feedbacks = ({ location, history }) => {
  const ratingOptions = [
    { key: "0", text: "1 stars", value: 1 },
    { key: "1", text: "2 stars", value: 2 },
    { key: "2", text: "3 stars", value: 3 },
    { key: "3", text: "4 stars", value: 4 },
    { key: "4", text: "5 stars", value: 5 },
  ];
  const pagingFromStorage = localStorage.getItem("pagingfeedback");
  const isFirstPage = location.search === "?p=1";

  const [
    updateMaidReviewsbyAdmin,
    {
      loading: updateReviewsMutationLoading,
      error: updateReviewsMutationError,
    },
  ] = useMutation(gql(updateReview));
  const [
    deleteMaidReviewsbyAdmin,
    {
      loading: deleteReviewsMutationLoading,
      error: deleteReviewsMutationError,
    },
  ] = useMutation(gql(deleteReview));

  const [paging, setPaging] = useState(
    pagingFromStorage && !isFirstPage
      ? JSON.parse(pagingFromStorage)
      : { tokens: [], prev: null, next: null }
  );

  const [isReviewModal, setIsReviewModal] = useState(false);
  const [isDeleteReviewModal, setIsDeleteReviewModal] = useState(false);
  const [authorName, setAuthorName] = useState("");
  const [rating, setRating] = useState(null);
  const [messageContent, setMessageContent] = useState("");
  const [reviewId, setReviewId] = useState(null);
  const [errors, setErrors] = useState({
    authorName: false,
    rating: false,
    content: false,
  });
  const [isAuthorNameEdit, setIsAuthorNameEdit] = useState(false);
  const [isCallingAPI, setIsCallingAPI] = useState(false);

  const handleCloseReviewModal = () => {
    if (!isCallingAPI) {
      setIsReviewModal(false);
      setAuthorName("");
      setRating(null);
      setMessageContent("");
      setReviewId(null);
      setErrors({});
      setIsAuthorNameEdit(false);
    }
  };

  const handleFetchReviewDataByIndex = (reviewDetails) => {
    setIsReviewModal(true);
    setAuthorName(
      reviewDetails?.authorName ||
        reviewDetails?.user?.firstName + " " + reviewDetails?.user?.lastName ||
        "Admin"
    );
    setRating(reviewDetails?.rating ?? null);
    setMessageContent(reviewDetails?.content ?? "");
    setReviewId(reviewDetails?.id);
    setIsAuthorNameEdit(reviewDetails?.user?.userType === "Customers");
  };

  const handleUpdateReview = async () => {
    const updateReviewBody = {
      id: reviewId,
      authorName: authorName,
      rating: rating,
      content: messageContent,
      dateCreated: Date.now().toString(),
    };
    try {
      if (authorName && rating && messageContent) {
        setIsCallingAPI(true);
        const response = await updateMaidReviewsbyAdmin({
          variables: updateReviewBody,
        });
        if (response?.data?.updateUsersReviews) {
          setIsCallingAPI(false);
          handleCloseReviewModal();
        } else {
          setIsCallingAPI(false);
        }
      }
    } catch (error) {
      console.log("updateMaidReviewsbyAdmin error", error);
    }
  };

  const handleDeleteReview = async () => {
    try {
      setIsCallingAPI(true);
      const response = await deleteMaidReviewsbyAdmin({
        variables: { id: reviewId },
      });
      if (response?.data?.deleteUsersReviews) {
        setIsCallingAPI(false);
        setIsDeleteReviewModal(false);
        handleCloseReviewModal();
      } else {
        setIsCallingAPI(false);
      }
    } catch (error) {
      console.log("deleteMaidReviewsbyAdmin error", error);
    }
  };

  const Paging = ({ nextToken, tokens }) => {
    return (
      <Button.Group className="paging">
        {tokens.length > 1 && (
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              tokens.splice(0, tokens.length);
              setPaging({
                tokens,
                prev: null,
                next: null,
              });
            }}
          >
            First
            <Icon name="fast backward" />
          </Button>
        )}
        {paging.next && (
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              tokens.pop();
              setPaging({
                tokens,
                prev: tokens[tokens.length - 1],
                next: paging.prev,
              });
              history.push({
                pathname: `${window.location.pathname}`,
                search: "",
              });
            }}
          >
            Prev
            <Icon name="left arrow" />
          </Button>
        )}
        {nextToken && (
          <Button
            icon
            labelPosition="right"
            onClick={() => {
              // setIsCallingAPI(true);
              tokens.push(nextToken);
              setPaging({
                tokens,
                prev: paging.next,
                next: nextToken,
              });
              history.push({
                pathname: `${window.location.pathname}`,
                search: "",
              });
              
            }}
          >
            Next
            <Icon name="right arrow" />
          </Button>
        )}
      </Button.Group>
    );
  };

  const TableView = ({ result }) => {
    const feedbacks = result.items;
    const nextToken = result.nextToken;
    const { tokens } = paging;

    return (
      <>
        <Paging nextToken={nextToken} tokens={tokens} />
        <Table celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>Date Time</Table.HeaderCell>
              <Table.HeaderCell width={2}>Customer / Author</Table.HeaderCell>
              <Table.HeaderCell width={2}>Maid</Table.HeaderCell>
              <Table.HeaderCell width={2}>Rating</Table.HeaderCell>
              <Table.HeaderCell width={5}>Comment</Table.HeaderCell>
              <Table.HeaderCell width={1}>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <RowContent feedbacks={feedbacks} />
        </Table>
        <Paging nextToken={nextToken} tokens={tokens} />
      </>
    );
  };

  const RowContent = ({ feedbacks }) => (
    <Table.Body>
      {feedbacks?.map((searchItem, index) => {
        const { user } = searchItem;
        const { maid } = searchItem;
        let objMaidName;

        try {
          objMaidName = maid?.name ? JSON.parse(maid.name) : "";
        } catch (error) {
          console.error("Maid name parse error", error);
        }

        const customerName =
          `${user?.firstName || ""} ${user?.lastName || ""}` || "";
        const maidName =
          `${objMaidName?.firstName || ""} ${objMaidName?.lastName || ""}` ||
          "";

        const dateCreated = moment(searchItem?.dateCreated, "x")?.format(
          "DD/MM/YYYY HH:mm"
        );
        return (
          <Table.Row key={index}>
            <Table.Cell>{dateCreated || ""}</Table.Cell>
            <Table.Cell className="text-capitalize">
              {user?.userType === "Customers" && (
                <Link to={`/customer/${user?.cognitoId}`} target="_blank">
                  {customerName}
                </Link>
              )}
              {user?.userType === "Admin" && searchItem?.authorName}
            </Table.Cell>
            <Table.Cell className="text-capitalize">
              <Link to={`/maid/${maid?.userId}`} target="_blank">
                {maidName}
              </Link>
            </Table.Cell>
            <Table.Cell>
              <Rating
                icon="star"
                defaultRating={searchItem?.rating}
                maxRating={5}
                disabled
              />
              {searchItem?.rating && ` (${searchItem?.rating})`}
            </Table.Cell>
            <Table.Cell>{searchItem.content || ""}</Table.Cell>
            <Table.Cell>
              <Button.Group>
                <Button
                  color="primary"
                  onClick={() => handleFetchReviewDataByIndex(searchItem)}
                >
                  Edit
                </Button>
                <Button
                  color="red"
                  onClick={() => {
                    setIsDeleteReviewModal(true);
                    setReviewId(searchItem?.id);
                  }}
                >
                  Delete
                </Button>
              </Button.Group>
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table.Body>
  );

  const AllSearches = () => {
    const { next } = paging;
    const limit = 15;
    localStorage.setItem("pagingfeedback", JSON.stringify(paging));
    return (
      <Connect
        query={graphqlOperation(getAllFeedbacks, {
          limit: limit,
          nextToken: next,
        })}
      >
        {({ data, loading }) => {
          const { listUsersReviewsByDate } = data;

          if (loading || !listUsersReviewsByDate)
            return (
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  Loading...
                </Message.Content>
              </Message>
            );

          return (
            <>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Header as="h3" color="grey">
                      Displaying {listUsersReviewsByDate?.items?.length} feedbacks
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <TableView
                result={{
                  items: listUsersReviewsByDate?.items,
                  nextToken: listUsersReviewsByDate?.nextToken,
                }}
              />
            </>
          );
        }}
      </Connect>
    );
  };
  // const memoizedComponent = useMemo(() => {
  //   return <AllSearches />;
  // }, [isCallingAPI]);
  return (
    <BaseLayout>
      <Grid divided>
        <Grid.Row>
          <Grid.Column width={3}>
            <Header as="h2">Feedbacks</Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {!isCallingAPI && !isReviewModal && !isDeleteReviewModal && <AllSearches />}
      {/* {memoizedComponent} */}
      <Modal
        closeIcon
        size="tiny"
        className="review-modal"
        onClose={() => handleCloseReviewModal()}
        onOpen={() => setIsReviewModal(true)}
        open={isReviewModal}
      >
        <ModalHeader>Edit Review</ModalHeader>
        <ModalContent>
          <Form>
            <Grid>
              <GridRow>
                <GridColumn width={8}>
                  <Form.Input
                    fluid
                    label="Author"
                    placeholder="Author Name"
                    value={authorName}
                    onChange={(e, data) => {
                      e.preventDefault();
                      setAuthorName(data?.value);
                      setErrors({
                        ...errors,
                        authorName: data?.value ? false : true,
                      });
                    }}
                    disabled={isAuthorNameEdit}
                  />
                  {errors.authorName && (
                    <span class="form-group-error">
                      Author name is required
                    </span>
                  )}
                </GridColumn>

                <GridColumn width={8}>
                  <Form.Select
                    fluid
                    label="Rating"
                    options={ratingOptions}
                    placeholder="Rating"
                    defaultValue={rating}
                    onChange={(e, data) => {
                      e.preventDefault();
                      setRating(data?.value);
                      setErrors({
                        ...errors,
                        rating: data?.value ? false : true,
                      });
                    }}
                  />
                  {errors.rating && (
                    <span class="form-group-error">Select rating</span>
                  )}
                </GridColumn>
              </GridRow>
            </Grid>

            <Grid>
              <GridRow>
                <GridColumn width={16}>
                  <Form.Field
                    rows={7}
                    control={TextArea}
                    label="Message"
                    placeholder="Type your message..."
                    value={messageContent}
                    onChange={(e, data) => {
                      e.preventDefault();
                      setMessageContent(data?.value);
                      setErrors({
                        ...errors,
                        content: data?.value ? false : true,
                      });
                    }}
                  />
                  {errors.content && (
                    <span className="form-group-error">
                      Review content is required
                    </span>
                  )}
                </GridColumn>
              </GridRow>
            </Grid>
          </Form>
        </ModalContent>

        <ModalActions
          className={`d-flex ${
            reviewId ? "justify-content-between" : "justify-content-end"
          }`}
        >
          {reviewId && (
            <Button
              basic
              color="red"
              onClick={() => setIsDeleteReviewModal(true)}
            >
              <Icon name="trash alternate" /> Delete this review
            </Button>
          )}
          <Button
            color="green"
            onClick={() => {
              !isCallingAPI &&
                setErrors({
                  authorName: authorName ? false : true,
                  rating: rating ? false : true,
                  content: messageContent ? false : true,
                });
              handleUpdateReview();
            }}
            loading={isCallingAPI}
          >
            Update
          </Button>
        </ModalActions>
      </Modal>
      <Modal
        // closeIcon
        size="mini"
        className="review-modal confirm-modal"
        onClose={() => setIsDeleteReviewModal(false)}
        open={isDeleteReviewModal}
      >
        <ModalContent>
          <div className="confirm-content">
            <p>
              {`Are you sure you want to delete this review by ‘${authorName ?? "customer"}’ ?`}
            </p>
          </div>
        </ModalContent>
        <ModalActions className="d-flex justify-content-center">
          <Button secondary onClick={() => setIsDeleteReviewModal(false)}>
            Cancel
          </Button>
          <Button color="red" onClick={() => handleDeleteReview()}>
            Yes, delete it
          </Button>
        </ModalActions>
      </Modal>
    </BaseLayout>
  );
  
};

export default withAuthenticator(Feedbacks);
