import React, { useState } from "react";

const CustomDropdown = (props) => {
    const {
        setSelectedDeepLinkOption,
        selectedDeepLinkOption,
        selectedUserType,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option) => {
        setSelectedDeepLinkOption(option);
        setIsOpen(false); // Close dropdown after selection
    };

    const renderOptionList = (options) => {
        return (
            <div className="sub-title-dropdown">
                <ul>
                    {options.map((option) => {
                        if (option.options) {
                            return (
                                <li className="sub-menu" key={option.label}>
                                    <span className="label">{option.label}</span>
                                    {renderOptionList(option.options)} {/* Render sub-options */}
                                </li>
                            );
                        }
                        return (
                            <li
                                className={`${selectedDeepLinkOption.label === option.label && "selected"}`}
                                key={option.value}
                                onClick={() => selectOption(option)}
                            >
                                {option.label}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    const maidDeepLinkOptions = [
        {
            label: "Homepage",
            options: [{ label: "Homepage", value: "" }],
        },
        {
            label: "Find Maids",
            options: [{ label: "Find Maids", value: "MyMaid" }],
        },
        {
            label: "Messages",
            options: [{ label: "Messages", value: "CustomerConversations" }],
        },
        {
            label: "Account",
            options: [
                { label: "Account", value: "Account" },
                { label: "Personal Info", value: "PersonalInformation" },
                { label: "Notifications", value: "Notifications" },
                { label: "Support", value: "Support" },
            ],
        },
    ];

    const customerDeepLinkOptions = [
        {
            label: "Homepage",
            options: [{ label: "Homepage", value: "" }],
        },
        {
            label: "Clients",
            options: [{ label: "Clients", value: "Clients" }],
        },
        {
            label: "Messages",
            options: [{ label: "Messages", value: "MaidConversations" }],
        },
        {
            label: "Account",
            options: [
                { label: "Account", value: "Account" },
                { label: "Profile", value: "" },
                { label: "Personal Info", value: "PersonalInformation" },
                { label: "Notifications", value: "Notifications" },
                { label: "Support", value: "Support" },
            ],
        },
    ];

    return (
        <div className="custom-select-dropdown">
            <div className="dropdown-button">
                <button onClick={toggleOpen}>
                    {selectedDeepLinkOption.label || "Select an option"}
                </button>
            </div>
            {isOpen && <div className="custom-option">
                {renderOptionList(selectedUserType === "Maids" ? maidDeepLinkOptions : selectedUserType === "Clients" ? customerDeepLinkOptions : [])}
            </div>}
        </div>
    );
};

export default CustomDropdown;
