import React, { useState } from 'react';
import { withAuthenticator, Connect } from 'aws-amplify-react';
import { graphqlOperation } from 'aws-amplify';
import { Button, Header, Icon, Image, Label, Table, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import BaseLayout from '../../components/templates/BaseLayout';
import DropdownList from '../../components/atoms/DropdownList';
import SearchBox from '../../components/molecules/SearchBox';
import { listMaidsByLocale } from '../../graphql/queries';
import countries from '../../constants/countries';

import './styles.css';

const moment = require('moment');

const Blocked = ({ location, history }) => {
	const pagingFromStorage = localStorage.getItem('pagingblkd');
	const isFirstPage = location.search === '?p=1';
	const [searchCriteria, setSearchCriteria] = useState({ limit: 10000 });
	const [paging, setPaging] = useState(
		pagingFromStorage && !isFirstPage
			? JSON.parse(pagingFromStorage)
			: { tokens: [], prev: null, next: null }
	);
	const [locale, setLocale] = useState('en-GB');

	const Paging = ({ nextToken, tokens }) => {
		return (
			<Button.Group>
				{tokens.length > 1 && (
					<Button
						icon
						labelPosition="left"
						onClick={() => {
							tokens.splice(0, tokens.length);
							setPaging({
								tokens,
								prev: null,
								next: null
							});
						}}
					>
						First
						<Icon name="fast backward" />
					</Button>
				)}
				{paging.next && (
					<Button
						icon
						labelPosition="left"
						onClick={() => {
							tokens.pop();
							setPaging({
								tokens,
								prev: tokens[tokens.length - 1],
								next: paging.prev
							});
							history.push({ pathname: `${window.location.pathname}`, search: '' });
						}}
					>
						Prev
						<Icon name="left arrow" />
					</Button>
				)}
				{nextToken && (
					<Button
						icon
						labelPosition="right"
						onClick={() => {
							tokens.push(nextToken);
							setPaging({
								tokens,
								prev: paging.next,
								next: nextToken
							});
							history.push({ pathname: `${window.location.pathname}`, search: '' });
						}}
					>
						Next
						<Icon name="right arrow" />
					</Button>
				)}
			</Button.Group>
		);
	};

	const TableView = ({ result }) => {
		const { items: maids, nextToken } = result;

		const { tokens } = paging;

		return (
			<>
				<Paging nextToken={nextToken} tokens={tokens} />
				<Table basic="very" celled collapsing>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Company Name/Name</Table.HeaderCell>
							<Table.HeaderCell>Postcode</Table.HeaderCell>
							<Table.HeaderCell>City</Table.HeaderCell>
							<Table.HeaderCell>Rate</Table.HeaderCell>
							<Table.HeaderCell>Email</Table.HeaderCell>
							<Table.HeaderCell>Date Registered</Table.HeaderCell>
							<Table.HeaderCell>Maid Updated</Table.HeaderCell>
							<Table.HeaderCell>Status</Table.HeaderCell>
							<Table.HeaderCell>Boosted</Table.HeaderCell>
							<Table.HeaderCell />
						</Table.Row>
					</Table.Header>
					<RowContent maids={maids} />
				</Table>
				<Paging nextToken={nextToken} tokens={tokens} />
			</>
		);
	};

	const RowContent = ({ maids }) => (
		<Table.Body>
			{maids.map(maid => {
				const {
					boost = false,
					userId,
					companyName,
					rate,
					email,
					dateRegistered,
					lastUpdatedDate,
					image,
					status
				} = maid;

				const name = maid?.name ? JSON.parse(maid.name) : "";
				const address = JSON.parse(maid.address);
				const { postcode, townCity } = address;
				const geoJson = JSON.parse(maid.geoJson);
				const coordinates = geoJson.coordinates.reverse().join('%2C');
				const dateCreated = moment(dateRegistered, 'x').format('DD/MM/YYYY HH:mm');
				const dateUpdated = lastUpdatedDate
					? moment(lastUpdatedDate, 'x').format('DD/MM/YYYY HH:mm')
					: '';

				const mapLink = `https://maps.google.com/?q=${coordinates}&z=16`;
				return (
          <Table.Row key={userId}>
            <Table.Cell>
              <Header as="h4" image>
                <Header.Content>
                  {companyName && (
                    <Header as="h3" block>
                      {companyName}
                    </Header>
                  )}
                  <Header as="h3">
                    {image && <Image src={image} circular />}
                    {name.firstName} {name.lastName || ""}
                  </Header>
                  <Header.Subheader>{userId}</Header.Subheader>
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>
              <Header as="h4">
                <Header.Content>
                  {postcode}
                  <Header.Subheader>
                    <Label
                      as="a"
                      href={mapLink}
                      target="_blank"
                      content="Map"
                      icon="map"
                      className="mapLink"
                    />
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>{townCity || ""}</Table.Cell>
            <Table.Cell>{rate || ""}</Table.Cell>
            <Table.Cell>
              <a href={`mailto:${email || ""}`}>{email || ""}</a>{" "}
            </Table.Cell>
            <Table.Cell>{dateCreated}</Table.Cell>
            <Table.Cell>{dateUpdated}</Table.Cell>
            <Table.Cell>{status}</Table.Cell>
            <Table.Cell>
              {boost ? (
                <Icon name="check" color="green" size="large" />
              ) : (
                <Icon name="close" color="red" size="large" />
              )}
            </Table.Cell>
            <Table.Cell>
              <Button.Group>
                <Button
                  primary
                  as={Link}
                  to={`/maid/${userId}`}
                  target="_blank"
                >
                  View
                </Button>
                <Button secondary as={Link} to={`/conversation/${userId}`}>
                  Message
                </Button>
              </Button.Group>
            </Table.Cell>
          </Table.Row>
        );
			})}
		</Table.Body>
	);

	const AllMaids = () => {
		const { next } = paging;
		const { limit, filter: searchFilter = {} } = searchCriteria;

		searchFilter.status = { eq: 'BLOCKED' };
		localStorage.setItem('pagingblkd', JSON.stringify(paging));

		return (
			<Connect
				query={graphqlOperation(listMaidsByLocale, {
					locale,
					limit,
					filter: searchFilter,
					nextToken: next
				})}
			>
				{({ data: { listMaidsByLocale }, loading }) => {
					if (loading || !listMaidsByLocale) return <h3>Loading...</h3>;

					const { items } = listMaidsByLocale;

					return (
						<>
							<Grid>
								<Grid.Row>
									<Grid.Column>
										<Header as="h3" color="grey">
											Displaying {items.length} maids
										</Header>
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<TableView result={listMaidsByLocale} />
						</>
					);
				}}
			</Connect>
		);
	};

	return (
		<BaseLayout>
			<Grid divided>
				<Grid.Row>
					<Grid.Column>
						<Header as="h2">Maids</Header>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={3}>
						<Header as="h3" content="Country" />
						<DropdownList
							options={countries}
							value={locale}
							onChange={(event, { value }) => setLocale(value)}
						/>
					</Grid.Column>
					<SearchBox
						onReset={() => setSearchCriteria({ limit: 50 })}
						onSearch={f => {
							if (f) {
								setSearchCriteria({
									filter: f
								});
							}
						}}
					/>
				</Grid.Row>
			</Grid>
			<AllMaids />
		</BaseLayout>
	);
};

export default withAuthenticator(Blocked);
