/* eslint-disable import/prefer-default-export */
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { API_GATEWAY } from '../constants/Urls';

const TIMEOUT = 90000;

export const getAppData = async pReqestBody => {
	const url = `${API_GATEWAY}prod/generic/getappdata`;

	try {
		const resData = await axios({
			method: 'POST',
			url,
			timeout: TIMEOUT,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-Requested-With': '*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers':
					'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-Requested-With,Accept,Access-Control-Allow-Origin,Access-Control-Allow-Methods,Access-Control-Allow-Headers',
				'Access-Control-Allow-Methods': 'OPTIONS,POST',
				'X-Api-Key': 'BZbhOYqKwV6CkD4IZOoiR8hwhdYpToSunFJNCWP8'
				// "X-Amz-Security-Token": jwtToken,
			},
			data: pReqestBody
		});
		if (resData && resData.data) {
			return resData.data;
		}
		return resData;
	} catch (error) {
		console.log('Error retrieving data (getAppData)', error);
		return error;
	}
};

export const deleteUserConversation = async pReqestBody => {
	const url = `${API_GATEWAY}prod/conversations/deleteconversation`;
	const objCurrentSession = await Auth.currentSession();
	const idToken = objCurrentSession.getIdToken();

	try {
		const resData = await axios({
			method: 'POST',
			url,
			timeout: TIMEOUT,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorizer: idToken.getJwtToken()
			},
			data: pReqestBody
		});
		return { status: 'OK', response: resData };
	} catch (error) {
		console.log('Error retrieving data (deleteUserConversation)', error);
		return { status: 'ERROR', response: error };
	}
};

export const disableUser = async pReqestBody => {
	const url = `${API_GATEWAY}prod/users/disableuser`;
	const objCurrentSession = await Auth.currentSession();
	const idToken = objCurrentSession.getIdToken();

	try {
		const resData = await axios({
			method: 'POST',
			url,
			timeout: TIMEOUT,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorizer: idToken.getJwtToken()
			},
			data: pReqestBody
		});
		return { status: 'OK', response: resData };
	} catch (error) {
		console.log('Error retrieving data (disableUser)', error);
		return { status: 'ERROR', response: error };
	}
};

export const approveCustomerFeedback = async pReqestBody => {
	const url = `${API_GATEWAY}prod/generic/approvefeedback`;
	const objCurrentSession = await Auth.currentSession();
	const idToken = objCurrentSession.getIdToken();

	try {
		const resData = await axios({
			method: 'POST',
			url,
			timeout: TIMEOUT,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorizer: idToken.getJwtToken()
			},
			data: pReqestBody
		});
		return { status: 'OK', response: resData };
	} catch (error) {
		console.log('Error approving feedback', error);
		return { status: 'ERROR', response: error };
	}
};
