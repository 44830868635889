import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { GMAP_API_KEY } from '../../../constants/keyCostants';

const moment = require('moment');

const containerStyle = {
	width: '100%',
	height: '620px'
};

const locations = [
    {locale :'en-GB', center : {lat:54.05382879201214,lng:-2.812349426638475}, zoom: 6},
    {locale :'en-US', center : {lat:41.24121,lng:-95.98911}, zoom: 4},
    {locale :'en-CA', center : {lat:57.77426685954189,lng:-103.07569703955939}, zoom: 4},
    {locale :'en-AU', center : {lat:-25.006398673018747,lng:134.80976475070264}, zoom: 4},
  ];

const divStyle = {
	background: `white`,
	border: `1px solid #ccc`,
	padding: 5,
    minheight: `150px`,
    width: `320px`,
}




const ProspectsInGoogleMapComponent = React.memo(({ prospects, locale }) => {

    const [selectedElement, setSelectedElement] = useState({prospect:null, activeMarker: null,showInfoWindow: true });	

    const currentLocation = locations.find(obj => {
        return obj.locale === locale;
    });

	return (
       
        <LoadScript googleMapsApiKey={GMAP_API_KEY}>
                <GoogleMap                    
                    mapContainerStyle={containerStyle}
                    center={currentLocation.center}
                    zoom={currentLocation.zoom}	
                    onClick={() => {
                        setSelectedElement({
                            prospect : null,
                            activeMarker: null,
                            showInfoWindow: true
                        })
                    }}							
                >
                    {/* Displaying markers */}
                    {prospects.map((prospect) => {	
                        if (prospect.geoJson){
                            
                            return (
                                <Marker
                                    key = {prospect.id} 
                                    position = {{ lat: Number(JSON.parse(prospect.geoJson).latitude), lng:Number(JSON.parse(prospect.geoJson).longitude) }}                                  
                                    onClick={(props, marker)=>{
                                        setSelectedElement({
                                            prospect : prospect,
                                            activeMarker: marker,
                                            showInfoWindow: true
                                        })
                                    }}
                                >
                                    {selectedElement.prospect && selectedElement.prospect.id && selectedElement.prospect.id ===  prospect.id ? (
                                        <InfoWindow
                                            visible={selectedElement.showInfoWindow}
                                            marker={selectedElement.activeMarker}                                            
                                            options= {{minWidth : 150 }}
                                            onCloseClick={() => {
                                                setSelectedElement({
                                                    prospect : null,
                                                    activeMarker: null,
                                                    showInfoWindow: true
                                                })
                                            }}
                                          >
                                            <div style={divStyle}>                                                
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>{'Phone'}</td>
                                                            <td>{':'}</td>
                                                            <td>{selectedElement.prospect.number}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{'Property'}</td>
                                                            <td>{':'}</td>
                                                            <td>{selectedElement.prospect.property}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{'Created'}</td>
                                                            <td>{':'}</td>                                                            
                                                            <td>{moment(selectedElement.prospect.dateCreated, 'x').format('DD ddd MMM YYYY HH:mm')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{'Postcode'}</td>
                                                            <td>{':'}</td>
                                                            <td>{selectedElement.prospect.postcode}</td>
                                                            
                                                        </tr>                                                        
                                                    </tbody>
                                                </table>                                                
                                            </div>
                                        </InfoWindow>
                                    ) : null}
                                </Marker>
                            );									
                        }
                        else {
                            return null;
                        }
                    })}

                </GoogleMap>
        </LoadScript>
	);
});

const ProspectsInGoogleMap = ({ prospects, locale }) => {
	return <ProspectsInGoogleMapComponent prospects={prospects} locale={locale}/>;
};

export default ProspectsInGoogleMap;
