import React from 'react';
import { Connect } from 'aws-amplify-react';
import { graphqlOperation } from 'aws-amplify';
import { getPushToken } from '../../../graphql/queries';

const NotificationMessage = React.memo(({ userId }) => {
	const pathName = window.location.pathname

	return (
		<Connect query={graphqlOperation(getPushToken, { userId })}>
			{({ data: { getPushToken: token }, loading }) => {
				if (loading) return (pathName === `/maid/${userId}` || pathName === `/customer/${userId}`) ? <h5>Retrieving...</h5> : <h3>Retrieving...</h3>;

				return (pathName === `/maid/${userId}` || pathName === `/customer/${userId}`) ? <h5>{token ? 'Enabled' : 'Disabled'}</h5> : <h3>Notifications {token ? 'enabled' : 'disabled'}</h3>
			}}
		</Connect>
	);
});

const Notifications = ({ userId }) => {
	return <NotificationMessage userId={userId} />;
};

export default Notifications;
