import React from 'react';
import { Container } from 'semantic-ui-react';
import Header from '../../molecules/Header';

const BaseLayout = ({ children }) => {
	return (
		<Container style={{ marginTop: '1em', marginBottom: '8em' }}>
			<Header />
			{children}
		</Container>
	);
};

export default BaseLayout;
