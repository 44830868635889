import React, { useState } from 'react';
import './styles.css';

const ChatInput = ({ onKeyDown }) => {
	const [messageInput, setMessageInput] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const submit = async () => {
		setErrorMessage('');

		if (messageInput.length > 0) {
			const result = await onKeyDown(messageInput);
			if (result.id) setMessageInput('');
			else setErrorMessage('Send failed: Try again');
		}
	};

	return (
		<div>
			<span className="error">{errorMessage}</span>
			<div className="chatContainer">
				<input
					className="chatInput"
					onChange={e => setMessageInput(e.target.value)}
					value={messageInput || ''}
					placeholder="Type a message..."
				/>
				<button className="chatButton" type="button" onClick={submit}>
					Submit
				</button>
			</div>
		</div>
	);
};

export default ChatInput;
