import React, { useState } from 'react';
import { withAuthenticator, Connect } from 'aws-amplify-react';
import { graphqlOperation } from 'aws-amplify';
import { Header, Grid, Divider } from 'semantic-ui-react';
import BaseLayout from '../../components/templates/BaseLayout';
import DropdownList from '../../components/atoms/DropdownList';
import { prospectsByLocale } from '../../graphql/queries';

import countries from '../../constants/countries';

import './styles.css';
import ProspectsInGoogleMap from '../../components/organisms/ProspectsInGoogleMap';


// Created By : Sanal
// Modified Date : 20220812
// Subject : Show all prospects in google maps

const MapProspects = React.memo(({ location, history }) => {
	
	
	const searchCriteria  = { limit: null, filter: null };

	const useIndex = 'locale-unresponses-index';
	const scanIndexForward = true;

	const [locale, setLocale] = useState('en-GB');

	const MaidsOnMap = () => {
	
		let searchFilter = searchCriteria.filter;
	
		const query = {
			locale,
			limit : 10,
			filter: searchFilter,
			nextToken: null
		};

		if (useIndex) {
			query.useIndex = useIndex;
		}

		if (scanIndexForward) {
			query.scanIndexForward = scanIndexForward;
		}


		return (
			<Connect query={graphqlOperation(prospectsByLocale, { locale })}>
			{({ data: { listProspectsByLocale }, loading }) => {
				if (loading || !listProspectsByLocale) return <h3>Loading...</h3>;

				return <ProspectsInGoogleMap prospects={listProspectsByLocale} locale={locale} />;
			}}
		</Connect>
		);	

		
	};

	return (
		<BaseLayout>
			<Grid divided>
				<Grid.Row>
					<Grid.Column width={3}>
						<Header as="h2">Prospects On Map</Header>
					</Grid.Column>
				
					<Grid.Column width={3}>
						<Grid.Row className="dropDown">
							<Header as="h3" content="Country" />
							<DropdownList
								options={countries}
								value={locale}
								onChange={(event, { value }) => {									
									setLocale(value)}}
							/>
						</Grid.Row>
					</Grid.Column>									
				</Grid.Row>
			</Grid>
			<Divider />
			<MaidsOnMap /> 
		</BaseLayout>
	);
});

export default withAuthenticator(MapProspects);
