// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	aws_project_region: 'eu-west-2',
	aws_cognito_identity_pool_id: 'eu-west-2:f1560b6d-554c-40fe-a1de-53b20e129e26',
	aws_cognito_region: 'eu-west-2',
	aws_user_pools_id: 'eu-west-2_GICpIEqz8',
	aws_user_pools_web_client_id: '3qtdagisth91didnsm74gnpej3',
	oauth: {},
	aws_appsync_graphqlEndpoint:
		'https://hd63aqe5ibfrvixpvltilzgz3i.appsync-api.eu-west-2.amazonaws.com/graphql',
	aws_appsync_region: 'eu-west-2',
	aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
	aws_appsync_apiKey: 'da2-5wjiymmjrna5tazja5xebdx3wq',
	aws_user_files_s3_bucket: 'maid-avatars-dev',
	aws_user_files_s3_bucket_region: 'eu-west-2'
};

export default awsmobile;
