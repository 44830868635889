const cleaningServices = [
	'Admin',
	'Babysitting',
	'Collect post',
	'Cooking',
	'Dog walking',
	'Dry cleaning',
	'Gardening',
	'Grocery shopping',
	'Ironing',
	'Laundry',
	'Decorating'
];

export default cleaningServices;
