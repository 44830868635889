import React, { useState } from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import { Header, Grid, Divider } from 'semantic-ui-react';
import BaseLayout from '../../components/templates/BaseLayout';
import DropdownList from '../../components/atoms/DropdownList';
import { listMaidsByLocale } from '../../graphql/queries';
import countries from '../../constants/countries';

import './styles.css';
import fetchItemsNextToken from '../../components/organisms/FetchItemsNextToken';
import MaidsInGoogleMap from '../../components/organisms/MaidsInGoogleMap';

// Created By : Sanal
// Modified Date : 20220811
// Subject : Show all maids in google maps

const statuses = [	
	{
		key: 'ACTIVE',
		text: 'ACTIVE',
		value: 'ACTIVE'
	},
	{
		key: 'PENDING',
		text: 'PENDING',
		value: 'PENDING'
	},
	{
		key: 'BLOCKED',
		text: 'BLOCKED',
		value: 'BLOCKED'
	},
	{
		key: 'ALL',
		text: 'ALL',
		value: 'ALL'
	},
];

const MapMaids = React.memo(({ location, history }) => {
	
	
	const [pageData, setPageData] = useState({ items: [], nextToken: undefined, loading : true});
	const [filterStatus, setStatus] = useState('ACTIVE');	
	const searchCriteria  = { limit: null, filter: null };

	const useIndex = 'locale-unresponses-index';
	const scanIndexForward = true;

	const [locale, setLocale] = useState('en-GB');

	const MaidsOnMap = () => {
			
	
		let searchFilter = searchCriteria.filter;
		
		if (filterStatus !== 'ALL') {
			if (!searchFilter) searchFilter = {};
			searchFilter.status = { eq: filterStatus };
		}

		const query = {
			locale,
			limit : null,
			filter: searchFilter,
			nextToken: null
		};

		if (useIndex) {
			query.useIndex = useIndex;
		}

		if (scanIndexForward) {
			query.scanIndexForward = scanIndexForward;
		}

		if (pageData.loading) {
			fetchItemsNextToken(listMaidsByLocale, query, [])
				.then(resData => {			
					if (resData && resData.items.length > 0) {						
						// console.log('resData.items');
						// console.log(resData.items);

						setPageData({
							items: resData.items, 
							loading : false,
							nextToken: resData.nextToken
						});

					} else {						
						setPageData({
							items: [],
							loading : false,
							nextToken: undefined
						});
					}				
				})
				.catch(error => {
					console.log(error);					
				});
		}

		if (pageData && pageData.loading) {
			return <h3>Loading...</h3>;
		}
		else {
			return (				
				<MaidsInGoogleMap maids={pageData.items} locale={locale} />
			);	
		}		
		
	};

	return (
		<BaseLayout>
			<Grid divided>
				<Grid.Row>
					<Grid.Column width={3}>
						<Header as="h2">Maids On Map</Header>
					</Grid.Column>
				
					<Grid.Column width={3}>
						<Grid.Row className="dropDown">
							<Header as="h3" content="Country" />
							<DropdownList
								options={countries}
								value={locale}
								onChange={(event, { value }) => {
									setPageData({
										items: null, 
										loading : true,
										nextToken: null
									});
									setLocale(value)}}
							/>
						</Grid.Row>
					</Grid.Column>
					<Grid.Column width={3}>
						<Grid.Row className="dropDown">
							<Header as="h3" content="Status" />
							<DropdownList
								options={statuses}
								value={filterStatus}
								onChange={(event, { value }) => {
									setPageData({
										items: null, 
										loading : true,
										nextToken: null
									});
									setStatus(value);
									}
								}
							/>
						</Grid.Row>
					</Grid.Column>					
				</Grid.Row>
			</Grid>
			<Divider />
			<MaidsOnMap /> 
		</BaseLayout>
	);
});

export default withAuthenticator(MapMaids);
