import React, { useEffect } from 'react';
import { withAuthenticator, Connect } from 'aws-amplify-react';
import { graphqlOperation } from 'aws-amplify';
import { Header, Grid, Statistic } from 'semantic-ui-react';
import BaseLayout from '../../components/templates/BaseLayout';
import { countProspects, listDashboard } from '../../graphql/queries';

const ConversationCount = () => {
	return (
		<Connect query={graphqlOperation(listDashboard, { limit: 100000 })}>
			{({ data: { listDashboards: dashboard }, loading }) => {
				if (loading || !dashboard) return <h3>Loading...</h3>;

				const totalMaidCustomerChats = dashboard.items.filter(
					x => x.ItemName === 'TotalMaidCustomerChats'
				);

				return <span>{totalMaidCustomerChats[0].ItemVal}</span>;
			}}
		</Connect>
	);
};

const MaidSupportConversationCount = () => {
	return (
		<Connect query={graphqlOperation(listDashboard, { limit: 100000 })}>
			{({ data: { listDashboards: dashboard }, loading }) => {
				if (loading || !dashboard) return <h3>Loading...</h3>;

				const totalSupportChats = dashboard.items.filter(
					x => x.ItemName === 'TotalSupportChats'
				);

				return <span>{totalSupportChats[0].ItemVal}</span>;
			}}
		</Connect>
	);
};

const MaidCount = () => {
	return (
		<Connect query={graphqlOperation(listDashboard, { limit: 100000 })}>
			{({ data: { listDashboards: dashboard }, loading }) => {
				if (loading || !dashboard) return <h3>Loading...</h3>;

				const totalMaids = dashboard.items.filter(
					x => x.ItemName === 'TotalRegisteredMaids'
				);
				const pending = dashboard.items.filter(x => x.ItemName === 'TotalPendingMaids');
				const active = dashboard.items.filter(x => x.ItemName === 'TotalActiveMaids');
				const blocked = dashboard.items.filter(x => x.ItemName === 'TotalBlockedMaids');

				return (
					<>
						<Statistic>
							<Statistic.Value>{totalMaids[0].ItemVal}</Statistic.Value>
							<Statistic.Label>Registered</Statistic.Label>
						</Statistic>
						<Statistic>
							<Statistic.Value>{active[0].ItemVal}</Statistic.Value>
							<Statistic.Label>Active</Statistic.Label>
						</Statistic>
						<Statistic>
							<Statistic.Value>{pending[0].ItemVal}</Statistic.Value>
							<Statistic.Label>Pending</Statistic.Label>
						</Statistic>
						<Statistic>
							<Statistic.Value>{blocked[0].ItemVal}</Statistic.Value>
							<Statistic.Label>Blocked</Statistic.Label>
						</Statistic>
					</>
				);
			}}
		</Connect>
	);
};

const CustomerCount = () => {
	return (
		<Connect query={graphqlOperation(listDashboard, { limit: 100000 })}>
			{({ data: { listDashboards: dashboard }, loading }) => {
				if (loading || !dashboard) return <h3>Loading...</h3>;

				const totalCustomers = dashboard.items.filter(x => x.ItemName === 'TotalCustomers');
				return <span>{totalCustomers[0].ItemVal}</span>;
			}}
		</Connect>
	);
};

const ProspectsCount = () => {
	return (
		<Connect query={graphqlOperation(countProspects)}>
			{({ data, loading }) => {
				if (loading || !data) return <h3>Loading...</h3>;
				return <span>{data.listProspects.items.length}</span>;
			}}
		</Connect>
	);
};

const Home = () => {
	useEffect(() => {}, []);

	return (
		<BaseLayout>
			<Grid padded columns={2}>
				<Grid.Column>
					<Header as="h2" image="/images/icons/clients.png" content="Customers" />
					<Statistic.Group color="blue">
						<Statistic>
							<Statistic.Value>
								<CustomerCount />
							</Statistic.Value>
							<Statistic.Label>Registered</Statistic.Label>
						</Statistic>
					</Statistic.Group>
				</Grid.Column>
				<Grid.Column>
					<Header as="h2" image="/images/icons/maid.png" content="Maids" />
					<Statistic.Group color="green">
						<MaidCount />
					</Statistic.Group>
				</Grid.Column>
				<Grid.Column>
					<Header as="h2" image="/images/icons/messages.png" content="Support Chats" />
					<Statistic.Group color="grey">
						<Statistic>
							<Statistic.Value>
								<MaidSupportConversationCount />
							</Statistic.Value>
							<Statistic.Label>Total</Statistic.Label>
						</Statistic>
					</Statistic.Group>
				</Grid.Column>
				<Grid.Column>
					<Header
						as="h2"
						image="/images/icons/messages.png"
						content="Maid-Customer Chats"
					/>
					<Statistic.Group color="purple">
						<Statistic>
							<Statistic.Value>
								<ConversationCount />
							</Statistic.Value>
							<Statistic.Label>Total</Statistic.Label>
						</Statistic>
					</Statistic.Group>
				</Grid.Column>
				<Grid.Column>
					<Header as="h2" image="/images/icons/prospects.png" content="Prospects" />
					<Statistic.Group color="olive">
						<Statistic>
							<Statistic.Value>
								<ProspectsCount />
							</Statistic.Value>
							<Statistic.Label>Total</Statistic.Label>
						</Statistic>
					</Statistic.Group>
				</Grid.Column>
			</Grid>
		</BaseLayout>
	);
};

export default withAuthenticator(Home);
