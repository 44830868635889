export default [
	{
		key: 'en-GB',
		text: 'UK',
		value: 'en-GB'
	},
	{
		key: 'en-US',
		text: 'USA',
		value: 'en-US'
	},
	{
		key: 'en-CA',
		text: 'Canada',
		value: 'en-CA'
	},
	{
		key: 'en-AU',
		text: 'Australia',
		value: 'en-AU'
	}
];
